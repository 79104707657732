<template>
	<div class="orderSetting">
		<frame :top1="top1" :top2="top2">
			<contents></contents>
		</frame>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	import contents from './componSetting/SettingCont.vue'
	export default {
		name: 'orderSetting',
		data() {
			return {
				top1: '5-4',
				top2: ['5'],
			}
		},
		components: {
			frame,
			contents
		},
		created() {
			let token = this.$storage.getLocal('token');
			this.$request.selOrderList({
				token,
				name: '',
				order: '',
				page: 1,
				limit: 10,
				status: 'whole',
				time: '',
				type: this.$storage.getLocal('type')
			}).then(res => {
				if (res.code == 0) {
					this.datas = res.data.data
					this.num = res.data.cunt
				}
			})
		},
		methods: {
			cut(item) {
				let set = 'whole'
				if (item == '待付款') {
					set = 0
				} else if (item == '待发货') {
					set = 1
				} else if (item == '已发货') {
					set = 2
				} else if (item == '已完成') {
					set = 3
				} else if (item == '已关闭') {
					set = 4
				} else if (item == '申请退款') {
					set = 5
				} else if (item == '已退款') {
					set = 6
				} else if (item == '全部订单') {
					set = 'whole'
				}
				let token = this.$storage.getLocal('token');
				this.$request.selOrderList({
					token,
					name: '',
					order: '',
					page: 1,
					limit: 10,
					status: set,
					time: '',
					type: this.$storage.getLocal('type')
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data
						this.num = res.data.cunt
					}
				})
			},
			search(item) {
				let newTime = ''
				if(item.date){
					newTime = item.date.getFullYear() + '-' + (item.date.getMonth() + 1) + '-' + item.date.getDate()
				}
				
				let token = this.$storage.getLocal('token');
				this.$request.selOrderList({
					token,
					name: item.phoneName,
					order: item.goodsName,
					page: 1,
					limit: 10,
					status: 'whole',
					time: newTime,
					type: this.$storage.getLocal('type')
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data
						this.num = res.data.cunt
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
</style>
