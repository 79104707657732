<template>
	<div id="setting">
		<div class="content">
			<div class="front">秒杀订单超过：</div>
			<div class="middle">
				<Input v-model="fromData.seckill">
					<span slot="append">分</span>
				</Input>
			</div>
			<div class="behind"> 未付款，订单自动关闭</div>
		</div>
		<div class="content">
			<div class="front">正常订单超过：</div>
			<div class="middle">
				<Input v-model="fromData.normal">
					<span slot="append">分</span>
				</Input>
			</div>
			<div class="behind"> 未付款，订单自动关闭</div>
		</div>
		<div class="content">
			<div class="front">发货超过：</div>
			<div class="middle">
				<Input v-model="fromData.deliver">
					<span slot="append">天</span>
				</Input>
			</div>
			<div class="behind"> 未收货，订单自动完成</div>
		</div>
		<div class="content">
			<div class="front">订单完成超过：</div>
			<div class="middle">
				<Input v-model="fromData.accomplish">
					<span slot="append">天</span>
				</Input>
			</div>
			<div class="behind"> 自动结束交易，不能申请售后</div>
		</div>
	<!-- 	<div class="content">
			<div class="front">订单完成超过：</div>
			<div class="middle">
				<Input v-model="fromData.evaluate">
					<span slot="append">天</span>
				</Input>
			</div>
			<div class="behind"> 自动五星好评</div>
		</div> -->
		<div class="but">
			<Button type="info" @click='present()' v-if='ordersId'>修改</Button>
			<Button type="info" @click='present()' v-if='!ordersId'>添加</Button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'setting',
		data() {
			return {
				fromData:{
					seckill:'',
					normal: '',
					deliver: '',
					accomplish: '',
					evaluate: ''
				},
				ordersId: '',
			}
		},
		created() {
			let token = this.$storage.getLocal('token');
			this.$request.orderSetList({
				type:this.$storage.getLocal('type'),
				token
			}).then(res => {
				if (res.code == 0) {
					if(res.data){
						this.fromData.seckill = res.data.order_seckill_time;
						this.fromData.normal = res.data.order_time;
						this.fromData.deliver = res.data.order_complete;
						this.fromData.accomplish = res.data.order_end;
						this.fromData.evaluate = res.data.order_praise;
						this.ordersId = res.data.ordersId;
					}else{
						
					}
				} else {
					this.$notify({
						title: '失败',
						message: res.msg,
						type: 'warning'
					});
				}
			})
			
		},
		methods: {
			present(){
				let token = this.$storage.getLocal('token');
				this.$request.orderSetUp({
					token,
					storeid: '',
					ordersId: this.ordersId? this.ordersId : '',
					type:this.$storage.getLocal('type'),
					seckill: this.fromData.seckill,
					normal: this.fromData.normal,
					deliver: this.fromData.deliver,
					accomplish: this.fromData.accomplish,
					// evaluate: this.fromData.evaluate
				}).then(res => {
					if (res.code == 0) {
						this.ordersId ? this.$Message.success('修改成功') : this.$Message.success('添加成功') 
					} else {
						this.$notify({
							title: '失败',
							message: res.msg,
							type: 'warning'
						});
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	#setting{
		width: 80%;
		margin-top: 100px;
		margin-left: 200px;
	}
	.content{
		width: 50%;
		margin: 0 auto;
		margin-top: 50px;
		display: flex;
		justify-content: space-between;
	}
	.front,.behind{
		width: 30%;
	}
	.front{
		text-align: right;
	}
	.behind{
		text-align: left;
	}
	.middle{
		width: 40%;
	}
	.but{
		margin-top: 100px;
	}
</style>
